.btn100 {
    background-color:black;
    color: white;
    text-align: center;
    text-decoration: none;
    font-family: 'Arial, Helvetica, sans-serif';
    font: bold;
    border-radius: 1rem;
    height: 3rem;
    width: 5rem;
    margin: 0.5rem;
  }
  .btn90 {
    background-color:black;
    color: white;
    text-align: center;
    text-decoration: none;
    font-family: 'Arial, Helvetica, sans-serif';
    font: bold;
    border-radius: 0.9rem;
    height: 2.7rem;
    width: 4.5rem;
    margin: 0.45rem;
  }
  .btn80 {
    background-color:black;
    color: white;
    text-align: center;
    text-decoration: none;
    font-family: 'Arial, Helvetica, sans-serif';
    font: bold;
    border-radius: 0.8rem;
    height: 2.4rem;
    width: 4rem;
    margin: 0.4rem;
  }
  .btn70 {
    background-color:black;
    color: white;
    text-align: center;
    text-decoration: none;
    font-family: 'Arial, Helvetica, sans-serif';
    font: bold;
    border-radius: 0.7rem;
    height: 2.1rem;
    width: 3.5rem;
    margin: 0.35rem;
  }
  .btn60 {
    background-color:black;
    color: white;
    text-align: center;
    text-decoration: none;
    font-family: 'Arial, Helvetica, sans-serif';
    font: bold;
    border-radius: 0.6rem;
    height: 1.8rem;
    width: 3rem;
    margin: 0.3rem;
  }

  .btn50 {
    background-color:black;
    color: white;
    text-align: center;
    text-decoration: none;
    font-family: 'Arial, Helvetica, sans-serif';
    font: bold;
    border-radius: 0.5rem;
    height: 1.5rem;
    width: 2.5rem;
    margin: 0.25rem;
  }

  .btnHover:hover {
    background-color:gold;
    color:black;
  }