.appContainer {
  background-color: white;
}

.row {
  display: flex;
  flex-direction: row;
}
.column {
  display: flex;
  flex-direction: column;
}

.header {
  background-color: black;
  text-align: center;
  color: white;
  font: bold;
  font-family: 'Times New Roman', Times, serif;
  font-size: 5rem
}



